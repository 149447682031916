import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CustomizeSnackBar from '../../components/Snackbar';
import DateSelector from '../../components/DateSelector';
import AutoComplete from '../../components/AutoComplete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EnviosService from '../../services/envios';
import GuiaService from '../../services/guia';
import DeleteObjectDialog from '../../components/DeleteObjectDialog';
import DownloadPdfDialog from '../../components/DownloadPdfDialog';
import PDFGenerator from '../../components/PDFGenerator'
import DocumentGuia from '../../components/DocumentGuia'
import DocumentManifiesto from "./DocumentManifiesto";
import Button from '@mui/material/Button';
import EnviosCreateUpdate from './EnviosCreateUpdate';
import Dialog from '@mui/material/Dialog'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinearProgress from '@mui/material/LinearProgress';

const EnviosInterprovinciales = (props) => {
    const [enviosData, setEnviosData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [fechaFilter, setFechaFilter] = useState(null)
    const [origen, setOrigen] = useState([])
    const [origenSelected, setOrigenSelected] = useState(null);
    const [inputOrigen, setInputOrigen] = useState('');

    const [destino, setDestino] = useState([])
    const [destinoSelected, setDestinoSelected] = useState(null);
    const [inputDestino, setInputDestino] = useState('');

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [openSalidaDialog, setOpenSalidaDialog] = useState(false)
    const [tokenActual, setTokenActual] = useState('');
    const [openDescargaDialog, setOpenDescargaDialog] = useState(false)
    const [openGuiasDialog, setOpenGuiasDialog] = useState(false)

    const [selectedManifiestoId, setSelectedManifiestoId] = useState(null)
    const [selectedUnidadPlaca, setSelectedUnidadPlaca] = useState(null)
    const [selectedIsGeneratedLogiTMellizo, setSelectedIsGeneratedLogiTMellizo] = useState(null)
    const [pdfDownload, setPdfDownload] = useState();
    const [guiasDownload, setGuiasDownload] = useState();
    const [isFinished, setIsFinished] = useState(1);
    const [selectedEnvio, setSelectedEnvio] = useState(null)

    const [titleDialog, setTitleDialog] = useState('');
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);
    const [isCreateDialog, setIsCreateDialog] = useState(true);

    const [isDataWaitig, setIsDataWaiting] = useState(true);
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).EnviosInterprovinciales;

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)

        findAllEnvios(
            loggedDashboardJSON,
            currentPage - 1,
            formatDateForString(fechaFilter),
            origenSelected ? origenSelected.id : null
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    }, [currentPage, activateFilters]);

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllOrigen(loggedDashboardJSON)
    }, [])


    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllEnvios = async (token, page, fecha, origen) => {
        setIsDataWaiting(true);
        try {
            const responseData = await EnviosService.getEnvios(token, page, fecha, origen);
            setEnviosData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };


    const findAllOrigen = async (token) => {
        try {
            const responseData = await EnviosService.getOrigenes(token);
            setOrigen(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllDestino = async (token, idManifiesto) => {
        try {
            const responseData = await EnviosService.getDestinos(token, idManifiesto);
            setDestino(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const handleFechaFilter = (date) => {
        setFechaFilter(date)
    }


    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    const renderFechaFilter = () => {
        return (
            <Grid item xs={6} sm={2} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-filter"
                    label="Fecha"
                    selectedValue={fechaFilter}
                    onChange={handleFechaFilter}>
                </DateSelector>
            </Grid>
        )
    }

    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    const handleOrigenSelectedChange = (event, newValue) => {
        setOrigenSelected(newValue ? newValue : null)
    }


    const handleInputOrigenChange = (event, newValue) => {
        setInputOrigen(newValue)
    }
    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }
    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderOrigenFilter = () => {
        return (
            <Grid item xs={5} sm={4.75} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'origen-filter',
                    origenSelected,
                    handleOrigenSelectedChange,
                    inputOrigen,
                    handleInputOrigenChange,
                    origen,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Lugar Origen',
                    'small'
                )}
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setFechaFilter('')
        setOrigenSelected(null)
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderFechaFilter()}
                        {renderOrigenFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }


    const tableColumns = [
        { id: 'columnCodigo', align: 'center', label: 'Código', minWidth: 75, format: 'string' },
        { id: 'columnFecha', align: 'center', label: 'Fecha', minWidth: 75, format: 'string' },
        { id: 'columnLugarOrigen', align: 'center', label: 'Lugar Origen', minWidth: 75, format: 'string' },
        { id: 'columnChofer', align: 'center', label: 'Chofer', minWidth: 75, format: 'string' },
        { id: 'columnPlaca', align: 'center', label: 'Placa', minWidth: 75, format: 'string' },
        { id: 'columnPrecio', align: 'center', label: 'Precio', minWidth: 75, format: 'string' },
        { id: 'columnPeso', align: 'center', label: 'Peso', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Status', minWidth: 75, format: 'string' },
        { id: 'columnOpciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }

    const handleClickOpenDownload = (idManifiesto, isGenerateNew) => {
        setIsFinished(1);
        setSelectedManifiestoId(idManifiesto)
        setSelectedIsGeneratedLogiTMellizo(isGenerateNew)
        findAllDestino(tokenActual, idManifiesto)
        setOpenDescargaDialog(true)
    };

    const handleClickOpenGuias = (idManifiesto) => {
        setIsFinished(1);
        setSelectedManifiestoId(idManifiesto)
        setOpenGuiasDialog(true)
    };

    const handleClickOpenEdit = (envioInput) => {
        setSelectedEnvio(envioInput)
        setTitleDialog('Actualizar Envío')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
    };

    const handleClickOpenExit = (idManifiesto, unidadPlaca) => {
        setSelectedManifiestoId(idManifiesto)
        setSelectedUnidadPlaca(unidadPlaca)
        setOpenSalidaDialog(true)
    };

    const renderActionTableButtons = (envios) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    <Grid item xs={3} sm={3} md={3} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton disabled={envios.procesoFinish == 1} aria-label="view" color="info" onClick={() => handleClickOpenDownload(envios.idManifiesto, envios.generateLogiT)}>
                            <VisibilityIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                    {
                        permisos.includes(2) || permisos.includes(3) ?
                            <Grid item xs={3} sm={3} md={3} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton disabled={envios.procesoFinish == 1 || envios.generateLogiT == false} aria-label="view" color="info" onClick={() => handleClickOpenGuias(envios.idManifiesto, envios.generateLogiT)}>
                                    <FileCopyIcon fontSize="medium" />
                                </IconButton>
                            </Grid> : null
                    }
                    {
                        permisos.includes(3) ?
                            <Grid item xs={3} sm={3} md={3} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton disabled={envios.procesoFinish == 1 || envios.enviado} aria-label="edit" color="warning" onClick={() => handleClickOpenEdit(envios)}>
                                    <EditIcon fontSize="medium" />
                                </IconButton>
                            </Grid> : null
                    }
                    {
                        permisos.includes(3) ?
                            <Grid item xs={3} sm={3} md={3} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton disabled={envios.procesoFinish == 1 || envios.enviado} aria-label="edit" color="success" onClick={() => handleClickOpenExit(envios.idManifiesto, envios.nroPlaca)}>
                                    <LocalShippingIcon fontSize="medium" />
                                </IconButton>
                            </Grid> : null
                    }
                </ThemeProvider>
            </Grid>
        )
    }


    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(enviosData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (enviosData.map(envios => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${envios.idManifiesto}`}>
                                            <TableCell align='center'>
                                                {envios.idManifiesto}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.fechaRegistro}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.sucursal}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.nombreChofer}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.nroPlaca}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/. {envios.totalPrecio}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.totalPeso} KGM
                                            </TableCell>
                                            <TableCell align='center'>
                                                {envios.procesoFinish == 1 ? <p style={{ color: 'orange', fontWeight: 'bold' }}>En Proceso</p> : null}
                                                {envios.procesoFinish == 2 ? <p style={{ color: 'green', fontWeight: 'bold' }}>Terminado</p> : null}
                                                {envios.procesoFinish == 3 ? <p style={{ color: 'red', fontWeight: 'bold' }}>Con Errores</p> : null}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(envios)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table>
                    {(enviosData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (enviosData.map(envios => (
                            <TableBody key={`${envios.idManifiesto}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Código:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.idManifiesto}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Fecha:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.fechaRegistro}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Lugar Origen :</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.sucursal}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Chofer :</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.nombreChofer}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Placa :</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.nroPlaca}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Precio :</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> S/. {envios.totalPrecio}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Peso :</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {envios.totalPeso} KGM</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Status :</div>
                                            <div> 
                                                {envios.procesoFinish == 1 ? <p style={{ color: 'orange', fontWeight: 'bold' }}>En Proceso</p> : null}
                                                {envios.procesoFinish == 2 ? <p style={{ color: 'green', fontWeight: 'bold' }}>Terminado</p> : null}
                                                {envios.procesoFinish == 3 ? <p style={{ color: 'red', fontWeight: 'bold' }}>Con Errores</p> : null}
                                            </div>
                                        </div>
                                        {renderActionTableButtons(envios)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const handleAddButton = () => {
        setSelectedEnvio(null);
        setTitleDialog('Registrar Envío')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(true)
    }

    const renderAddButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button color="success" onClick={handleAddButton} variant="contained">Registrar Envío</Button>
                </Grid>
            </Grid>
        )
    }

    const renderCreateUpdateDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'lg' : 'xs'} open={openCreateUpdateDialog} onClose={() => setOpenCreateUpdateDialog(false)}>
                <EnviosCreateUpdate
                    title={titleDialog}
                    token={tokenActual}
                    isCreateDialog={isCreateDialog}
                    selectedEnvio={selectedEnvio}
                    setDialogOpen={setOpenCreateUpdateDialog}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                    origenesList={origen}
                >
                </EnviosCreateUpdate>
            </Dialog>
        )
    }

    const handleSalida = async () => {
        try {
            await EnviosService.updateSalida(tokenActual, selectedManifiestoId);
            manageAlert('success', 'Unidad con placa ' + selectedUnidadPlaca + ' actualizado exitosamente', true)
            handleClearFilters()
            setOpenSalidaDialog(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderSalidaObject = (placa) => {
        return (
            <DeleteObjectDialog
                setOpen={setOpenSalidaDialog}
                deleteFunction={handleSalida}
                open={openSalidaDialog}
                title={'Salida del Vehículo'}
                contentMessage={'Posterior a este cambio no existe modificación. ¿Está seguro que desea registrar la salida del vehículo  ' + placa + '?'}
            />
        )
    }

    const handleDestinoSelectedChange = (event, newValue) => {
        setDestinoSelected(newValue ? newValue : null)
    }
    const handleInputDestinoChange = (event, newValue) => {
        setInputDestino(newValue)
    }

    const renderDestino = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '0.50rem' }}>
                {renderAutocompleteFilter(
                    'destino-filter',
                    destinoSelected,
                    handleDestinoSelectedChange,
                    inputDestino,
                    handleInputDestinoChange,
                    destino,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Lugar Destino',
                    'small'
                )}
            </Grid>
        )
    }

    const handleDescarga = async () => {
        try {
            setIsFinished(2);
            const responseData = await EnviosService.getManifiesto(tokenActual, selectedManifiestoId, destinoSelected ? destinoSelected.id : null)
            setPdfDownload(
                <Button variant="contained" color="success">
                    <PDFGenerator
                        setIsFinished={setIsFinished}
                        documento={<DocumentManifiesto data={responseData} />}
                        fileName={"Manifiesto  - " + selectedManifiestoId + ".pdf"}
                    />
                </Button>
            )
            setIsFinished(3);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderDownloadObject = (isGenerate) => {
        return (
            <DownloadPdfDialog
                setOpen={setOpenDescargaDialog}
                deleteFunction={handleDescarga}
                open={openDescargaDialog}
                title={'Descargar manifiesto'}
                contentMessage={
                    isGenerate ?
                        renderDestino() : '¿Está seguro que desea descargar el manifiesto?'
                }
                pdfDownload={pdfDownload}
                isFinished={isFinished}
            />
        )
    }


    const handleGuias = async () => {
        try {
            setIsFinished(2);
            const responseData = await GuiaService.getDetalle(tokenActual, selectedManifiestoId, '');
            setGuiasDownload(
                <Button variant="contained" color="success">
                    <PDFGenerator
                        setIsFinished={setIsFinished}
                        documento={<DocumentGuia data={responseData} />}
                        fileName={"Guias  - " + selectedManifiestoId + ".pdf"}
                    />
                </Button>
            )
            setIsFinished(3);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }
    const renderGuiasObject = () => {
        return (
            <DownloadPdfDialog
                setOpen={setOpenGuiasDialog}
                deleteFunction={handleGuias}
                open={openGuiasDialog}
                title={'Descarga de Guias de Transportista'}
                contentMessage=''
                pdfDownload={guiasDownload}
                isFinished={isFinished}
            />
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {permisos.includes(2)? renderAddButton():null}
            {renderCreateUpdateDialog()}
            {renderSalidaObject(selectedUnidadPlaca)}
            {renderDownloadObject(selectedIsGeneratedLogiTMellizo)}
            {renderGuiasObject()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default EnviosInterprovinciales;