import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';

import AutoComplete from '../../components/AutoComplete';
import MercaderiaCaracteristica from './MercaderiaCaracteristica';
import CustomizeCheckBox from '../../components/Checkbox';

import ClientesService from '../../services/clientes';
import MercaderiaService from '../../services/mercaderia';
import SucursalesService from '../../services/sucursales';

const MercaderiaCreateUpdate = ({
    title,
    token,
    isCreateDialog,
    mercaderiaId,
    setDialogOpen,
    reloadData,
    flagForReloadData,
    isSmUp,
    manageAlert,
    isGeneradoGtSelect
}) => {
    const [mercaderiaToUpdate, setMercaderiaToUpdate] = useState(null)

    const tiposEnvioData = [
        {
            id: 1,
            nombre: "Envío Normal"
        }
        // {
        //     id: 2,
        //     nombre: "Transbordo"
        // }
    ]
    const tiposPagoData = [
        {
            id: 1,
            nombre: "CREDITO"
        },
        {
            id: 2,
            nombre: "CONTADO"
        }
    ]
    const unidadesMedidaData = [
        {
            id: "KGM",
            nombre: "KILOGRAMO"
        },
        {
            id: "TNE",
            nombre: "TONELADAS"
        }
    ]
    const tiposMercaderiaData = [
        {
            id: 1,
            nombre: "Volumen"
        },
        /*{
            id: 2,
            nombre: "Peso Volumen"
        },*/
        {
            id: 3,
            nombre: "Peso"
        }
    ]
    const tiposMonedaData = [
        {
            id: "PEN",
            nombre: "Sol"
        },
        {
            id: "USD",
            nombre: "US Dollar"
        }
    ]
    const pagadoresData = [
        {
            id: "R",
            nombre: "Remitente"
        },
        {
            id: "D",
            nombre: "Destinatario"
        }
    ]

    const sucursalesData = [
        {
            id: 1,
            nombre: "Lima"
        },
        {
            id: 2,
            nombre: "Chimbote"
        },
        {
            id: 3,
            nombre: "Chao"
        },
        {
            id: 4,
            nombre: "Viru"
        },
        {
            id: 5,
            nombre: "Trujillo"
        },
    ]

    const [tiposEnvio, setTiposEnvio] = useState(tiposEnvioData)
    const [tipoEnvioSelected, setTipoEnvioSelected] = useState(null)
    const [inputTipoEnvio, setInputTipoEnvio] = useState('')

    const [tiposPago, setTiposPago] = useState(tiposPagoData)
    const [tipoPagoSelected, setTipoPagoSelected] = useState(null)
    const [inputTipoPago, setInputTipoPago] = useState('')

    const [guiaRemitente, setGuiaRemitente] = useState('')

    const [nroBultos, setNroBultos] = useState('')

    const [pesoTotal, setPesoTotal] = useState('')

    const [unidadesMedida, setUnidadesMedida] = useState(unidadesMedidaData)
    const [unidadMedidaSelected, setUnidadMedidaSelected] = useState(null)
    const [inputUnidadMedida, setInputUnidadMedida] = useState('')

    const [pagadores, setPagadores] = useState(pagadoresData)
    const buscarPagadorPorId = (id) => {
        const resultado = pagadores.find(pagador => pagador.id === id);
        return resultado !== undefined ? resultado : null;
    };
    const [pagadorSelected, setPagadorSelected] = useState(null)
    const [inputPagador, setInputPagador] = useState('')

    const [observaciones, setObservaciones] = useState('')

    const [docRemitente, setDocRemitente] = useState('')
    const [razonSocialRemitente, setRazonSocialRemitente] = useState('')
    const [direccionesRemitente, setDireccionesRemitente] = useState([])
    const [direccionRemitenteSelected, setDireccionRemitenteSelected] = useState(null)
    const [direccionRemitenteInput, setDireccionRemitenteInput] = useState('')
    const [isRemitenteSucursal, setIsRemitenteSucursal] = useState(false)
    const [direccionesRemitenteForSelect, setDireccionesRemitenteForSelect] = useState([])

    const [docDestinatario, setDocDestinatario] = useState('')
    const [razonSocialDestinatario, setRazonSocialDestinatario] = useState('')
    const [direccionesDestinatario, setDireccionesDestinatario] = useState([])
    const [direccionDestinatarioSelected, setDireccionDestinatarioSelected] = useState(null)
    const [direccionDestinatarioInput, setDireccionDestinatarioInput] = useState('')
    const [isDestinatarioSucursal, setIsDestinatarioSucursal] = useState(false)
    const [direccionesDestinatarioForSelect, setDireccionesDestinatarioForSelect] = useState([])

    const [referenciaLlegada, setReferenciaLlegada] = useState('')

    const [tiposMercaderia, setTiposMercaderia] = useState(tiposMercaderiaData)

    const [tiposMoneda, setTiposMoneda] = useState(tiposMonedaData)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [observacionesInternas, setObservacionesInternas] = useState('')

    const buildCaracteristicasWithOneEmpty = () => {
        return [
            {
                id: uuidv4(),
                cantidad: 0,
                nombre: '',
                tipoMercaderia: null,
                precioUnitario: 0,
                tipoMoneda: null,
                total: 0,
                unidadMedida: null,
                peso: 0,
                esNueva: true,
                estado: 1
            }
        ]
    }
    const [caracteristicas, setCaracteristicas] = useState(buildCaracteristicasWithOneEmpty())
    
    //const [direcciones, setDirecciones] = useState(buildDireccionesWithOneEmpty())
    // eslint-disable-next-line no-unused-vars
    const [nroMaxCaracteristicas, setNroMaxCaracteristicas] = useState(15)

    const [sucursales, setSucursales] = useState([])

    const [sucursalRemitenteSelected, setSucursalRemitenteSelected] = useState(null)
    const [sucursalRemitenteInput, setSucursalRemitenteInput] = useState('')

    const [sucursalDestinatarioSelected, setSucursalDestinatarioSelected] = useState(null)
    const [sucursalDestinatarioInput, setSucursalDestinatarioInput] = useState('')
    const [isFindStateOne, setIsFindStateOne] = useState(false)

    useEffect(() => {
        if(!isCreateDialog) {
            findMercaderiaById(token, mercaderiaId)
        }
        else {
            findAllSucursales()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findAllSucursales = async () => {
        try {
            const responseData = await SucursalesService.getSucursales(token)
            setSucursales(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    useEffect(() => {
        let pesoTotalEnKG = 0
        if(caracteristicas !== null) {
            caracteristicas.forEach(caracteristica => {
                if(caracteristica !== null && caracteristica.unidadMedida !== null){
                    let pesoCaracteristica = parseFloat(caracteristica.peso)
                    if(caracteristica.unidadMedida.id === "TNE") {
                        pesoCaracteristica = parseFloat(pesoCaracteristica * 1000)
                    }
                    pesoTotalEnKG = parseFloat(pesoTotalEnKG) + parseFloat(pesoCaracteristica)
                }
            })
        }
        setPesoTotal(pesoTotalEnKG)
        setUnidadMedidaSelected(unidadesMedidaData[0])
    }, [caracteristicas])

    const findMercaderiaById = async (token, mercaderiaId) => {
        try {
            const sucursalesResponse = await SucursalesService.getSucursales(token)
            if(sucursalesResponse) {
                setSucursales(sucursalesResponse)

                const mercaderiaData = await MercaderiaService.getMercaderiaById(token, mercaderiaId);
                if(mercaderiaData) {
                    setMercaderiaToUpdate(mercaderiaData ? mercaderiaData : null)
                    fillFormFields(mercaderiaData, sucursalesResponse)
                }
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const fillFormFields = (mercaderiaData, sucursalesResponse) => {
        setTipoEnvioSelected(mercaderiaData.indicadorTransbordo || null)
        setTipoPagoSelected(mercaderiaData.tipoPago || null)
        setGuiaRemitente(mercaderiaData.guiaRemitente || '')
        setNroBultos(mercaderiaData.numeroBultos !== null ? mercaderiaData.numeroBultos : '')
        setPesoTotal(mercaderiaData.totalKg !== null ? mercaderiaData.totalKg : '')
        setUnidadMedidaSelected(mercaderiaData.unidadMedida || null)
        setPagadorSelected(mercaderiaData.pagador !== null ? buscarPagadorPorId(mercaderiaData.pagador) : null)
        setObservaciones(mercaderiaData.observaciones || '')

        setIsRemitenteSucursal(mercaderiaData.direccionRemitenteIsASucursal)
        setDocRemitente(mercaderiaData.remitente !== null ? mercaderiaData.remitente.ruc : '')
        setRazonSocialRemitente(mercaderiaData.remitente !== null ? mercaderiaData.remitente.razonSocial : '')
        setDireccionesRemitente(mercaderiaData.remitente !== null ? mercaderiaData.remitente.direcciones : [])
        if(mercaderiaData.direccionRemitenteIsASucursal) {
            setDireccionesRemitenteForSelect(sucursalesResponse !== null ? sucursalesResponse : [])
            setDireccionRemitenteSelected(mercaderiaData.sucursalRemitente || null)
        }
        else {
            setDireccionesRemitenteForSelect(mercaderiaData.remitente !== null ? mercaderiaData.remitente.direcciones : [])
            setDireccionRemitenteSelected(mercaderiaData.direccionRemitente || null)
        }
        

        setIsDestinatarioSucursal(mercaderiaData.direccionDestinatarioIsASucursal)
        setDocDestinatario(mercaderiaData.destinatario !== null ? mercaderiaData.destinatario.ruc : '')
        setRazonSocialDestinatario(mercaderiaData.destinatario !== null ? mercaderiaData.destinatario.razonSocial : '')
        setDireccionesDestinatario(mercaderiaData.destinatario !== null ? mercaderiaData.destinatario.direcciones : [])
        if(mercaderiaData.direccionDestinatarioIsASucursal) {
            setDireccionesDestinatarioForSelect(sucursalesResponse !== null ? sucursalesResponse : [])
            setDireccionDestinatarioSelected(mercaderiaData.sucursalDestinatario || null)
        }
        else {
            setDireccionesDestinatarioForSelect(mercaderiaData.destinatario !== null ? mercaderiaData.destinatario.direcciones : [])
            setDireccionDestinatarioSelected(mercaderiaData.direccionDestinatario || null)
        }
        setReferenciaLlegada(mercaderiaData.referenciaLlegada || '')

        if(mercaderiaData.caracteristicas) {
            mercaderiaData.caracteristicas = mercaderiaData.caracteristicas.map(caracteristica => ({
                ...caracteristica,
                esNueva: false
            }))
        }

        setObservacionesInternas(mercaderiaData.observacionesInternas || '')

        setCaracteristicas(mercaderiaData.caracteristicas || [])
        const isFindStateOne = mercaderiaData.caracteristicas.some(item => item.estado === "1");
        setIsFindStateOne(isFindStateOne)
        console.log('isFindStateOne: '+isFindStateOne)

        // console.log(mercaderiaData )
    }

    const renderTitle = (title) => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem'}}>{title}</DialogTitle>
        )
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if(/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfDecimalNumber = (newValue, stateFunction) => {
        if(/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfLetterOrSpace = (newValue, stateFunction) => {
        const letters = /^[a-zA-Z0-9\s.\-_&+]*$/
        if(letters.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre (objeto) {
        return objeto.nombre
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size, disabled) => {
        return (
            <AutoComplete
                id = {id}
                value = {value}
                onChange = {onChange}
                inputValue = {inputValue}
                onInputChange = {onInputChange}
                options = {options}
                getOptionLabel = {getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width = {width}
                label = {label}
                size = {size}
                disabled={disabled}
            />
        )
    }

    const renderDividerTitle = (title) => {
        return(
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*padding: '1rem 1.25rem 0rem 1.25rem'*/}}>
                <Divider textAlign="left">
                    {title}
                </Divider>
            </Grid>
        )
    }

    const handleTipoEnvioSelectedChange = (event, newValue) => {
        setTipoEnvioSelected(newValue ? newValue : null)
    }

    const handleInputTipoEnvioChange = (event, newValue) => {
        setInputTipoEnvio(newValue)
    }

    const renderTipoEnvio = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'tipoEnvio-box',
                    tipoEnvioSelected,
                    handleTipoEnvioSelectedChange,
                    inputTipoEnvio,
                    handleInputTipoEnvioChange,
                    tiposEnvio,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Envío',
                    'small',
                    isGeneradoGtSelect!=0
                )}
            </Grid>
        )
    }

    const handleTipoPagoSelectedChange = (event, newValue) => {
        setTipoPagoSelected(newValue ? newValue : null)
        setPagadorSelected(null);
    }

    const handleInputTipoPagoChange = (event, newValue) => {
        setInputTipoPago(newValue)
    }

    const renderTipoPago = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'tipoPago-box',
                    tipoPagoSelected,
                    handleTipoPagoSelectedChange,
                    inputTipoPago,
                    handleInputTipoPagoChange,
                    tiposPago,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Pago',
                    'small',
                    isGeneradoGtSelect!=0
                )}
            </Grid>
        )
    }

    const renderGuiaRemitenteTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="guiaRemitente-textfield"
                    label="Guia Remitente"
                    variant="outlined"
                    value={guiaRemitente}
                    onChange={(event) => setGuiaRemitente(event.target.value)}
                    size='small'
                    disabled={isGeneradoGtSelect!=0}
                    inputProps={{
                        maxlength: 50
                    }}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderNroBultosTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="nroBultos-textfield"
                    label="Nro. Bultos"
                    variant="outlined"
                    value={nroBultos}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setNroBultos)}
                    size='small'
                    disabled={isGeneradoGtSelect!=0}
                    inputProps={{
                        maxlength: 9
                    }}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderPesoTotalTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="pesoTotal-textfield"
                    label="Peso total"
                    variant="outlined"
                    value={pesoTotal}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, setPesoTotal)}
                    size='small'
                    disabled
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleUnidadMedidaSelectedChange = (event, newValue) => {
        setUnidadMedidaSelected(newValue ? newValue : null)
    }

    const handleInputUnidadMedidaChange = (event, newValue) => {
        setInputUnidadMedida(newValue)
    }

    const renderUnidadMedida = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'unidadMedida-box',
                    unidadMedidaSelected,
                    handleUnidadMedidaSelectedChange,
                    inputUnidadMedida,
                    handleInputUnidadMedidaChange,
                    unidadesMedida,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Unidad medida',
                    'small',
                    true
                )}
            </Grid>
        )
    }

    const handlePagadorSelectedChange = (event, newValue) => {
        setPagadorSelected(newValue ? newValue : null)
    }

    const handleInputPagadorChange = (event, newValue) => {
        setInputPagador(newValue)
    }

    const renderPagador = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'pagador-box',
                    pagadorSelected,
                    handlePagadorSelectedChange,
                    inputPagador,
                    handleInputPagadorChange,
                    pagadores,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Pagador',
                    'small',
                    isGeneradoGtSelect!=0
                )}
            </Grid>
        )
    }

    const renderObservacionesTextField = () => {
        const size = tipoPagoSelected !== null && tipoPagoSelected.nombre === "CONTADO" ? 9 : 12
        return (
            <Grid item xs={12} sm={size} md={size} lg={size} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="observaciones-textfield"
                    label="Observaciones Cliente"
                    // label="Observaciones"
                    variant="outlined"
                    value={observaciones}
                    onChange={(event) => setObservaciones(event.target.value)}
                    size='small'
                    disabled={isGeneradoGtSelect!=0}
                    inputProps={{
                        maxlength: 100
                    }}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderObservacionesInternasTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="observacionesInternas-textfield"
                    label="Observaciones Internas"
                    variant="outlined"
                    value={observacionesInternas}
                    onChange={(event) => setObservacionesInternas(event.target.value)}
                    size='small'
                    disabled={isGeneradoGtSelect!=0}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleRemitenteDocChange = (value) => {
        setDocRemitente(value)
    }

    const findClienteById = async (token, clienteId) => {
        try {
            const clienteData = await ClientesService.getClienteById(token, clienteId);
            if(clienteData) {
                return {
                    nombre: clienteData.razonSocial,
                    direcciones: clienteData.direcciones.map(direccion => ({
                        id: direccion.id,
                        nombre: direccion.direccion+", "+direccion.distrito.nombre+", "+direccion.provincia.nombre+", "+direccion.departamento.nombre
                    }))
                }
            }
            
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const handleRemitenteDocSearch = async (value) => {
        if(value) {
            setRazonSocialRemitente('')
            setDireccionRemitenteSelected(null)
            const remitenteData = await findClienteById(token, value)
            if(remitenteData) {
                setRazonSocialRemitente(remitenteData.nombre)
                setDireccionesRemitente(remitenteData.direcciones)
                setDireccionRemitenteSelected(null)
                setDireccionesRemitenteForSelect(remitenteData.direcciones)
                setIsRemitenteSucursal(false)
            }
        }
    }

    const handleDestinatarioDocChange = (value) => {
        setDocDestinatario(value)
    }

    const handleDestinatarioDocSearch = async (documento) => {
        setRazonSocialDestinatario('')
        setDireccionDestinatarioSelected(null)
        if(documento) {
            const destinatarioData = await findClienteById(token, documento)
            if(destinatarioData) {
                setRazonSocialDestinatario(destinatarioData.nombre)
                setDireccionesDestinatario(destinatarioData.direcciones)
                setDireccionDestinatarioSelected(null)
                setDireccionesDestinatarioForSelect(destinatarioData.direcciones)
                setIsDestinatarioSucursal(false)
            }
        }
    }

    const renderClienteDoc = (key, value, textFieldOnChange, handleBtnSearch) => {
        return (
            <Grid item xs={12} sm={5} md={5} lg={5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <Grid container columnSpacing={{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}>
                    <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} style={{ justifyContent: "center", display: "flex" }}>
                        <TextField
                            autoComplete="off"
                            id={`nroDoc-${key}-textfield`}
                            label="Nro. Documento"
                            variant="outlined"
                            value={value}
                            onChange={(event) => changeStateOnlyIfNumber(event.target.value, textFieldOnChange)}
                            size='small'
                            disabled={isGeneradoGtSelect!=0}
                            fullWidth>
                        </TextField>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton disabled={isGeneradoGtSelect!=0} aria-label="Buscar" color="primary" onClick={() => handleBtnSearch(value)} >
                            <SearchIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderClienteRazonSocialTextField = (key, value) => {
        return (
            <Grid item xs={12} sm={7} md={7} lg={7} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id={`razonSocial-${key}-textfield`}
                    label="Nombres o Razón Social"
                    variant="outlined"
                    value={value}
                    //onChange={(event) => changeStateOnlyIfLetterOrSpace(event.target.value, setRazonSocial)}
                    size='small'
                    disabled={true}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderCheckbox = (id, label, handleChange, isChecked, size, isDisabled) => {
        return (
            <CustomizeCheckBox
                id={id}
                label={label}
                handleChange={handleChange}
                isChecked={isChecked}
                size={size}
                isDisabled={isDisabled || isGeneradoGtSelect!=0}
            />
        )
    }

    const handleRemitenteCheckBoxChecked = () => {
        setDireccionRemitenteSelected(null)
        if (isRemitenteSucursal) {
            setDireccionesRemitenteForSelect(direccionesRemitente)
            setIsRemitenteSucursal(false)
        }
        else {
            setDireccionesRemitenteForSelect(sucursales)
            setIsRemitenteSucursal(true)
        }
    }

    const handleDestinatarioCheckBoxChecked = () => {
        setDireccionDestinatarioSelected(null)
        if (isDestinatarioSucursal) {
            setDireccionesDestinatarioForSelect(direccionesDestinatario)
            setIsDestinatarioSucursal(false)
        }
        else {
            setDireccionesDestinatarioForSelect(sucursales)
            setIsDestinatarioSucursal(true)
        }
    }

    const renderClienteSucursalCheckbox = (id, label, handleChange, isChecked, size, isDisabled) => {
        return (
            <Grid item xs={12} sm={2.25} md={1.5} lg={1.5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderCheckbox(id, label, handleChange, isChecked, size, isDisabled)}
            </Grid>
        )
    }

    const handleRemitenteDireccionSelectedChange = (event, newValue) => {
        setDireccionRemitenteSelected(newValue ? newValue : null)
    }

    const handleRemitenteDireccionInputChange = (event, newValue) => {
        setDireccionRemitenteInput(newValue)
    }

    const handleDestinatarioDireccionSelectedChange = (event, newValue) => {
        setDireccionDestinatarioSelected(newValue ? newValue : null)
    }

    const handleDestinatarioDireccionInputChange = (event, newValue) => {
        setDireccionDestinatarioInput(newValue)
    }

    const renderClienteDirecciones = (key, selected, handleSelected, input, handleInput, options, label) => {
        return (
            <Grid item xs={12} sm={9.75} md={10.5} lg={10.5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `${key}-box`,
                    selected,
                    handleSelected,
                    input,
                    handleInput,
                    options,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    label,
                    'small',
                    isGeneradoGtSelect!=0
                )}
            </Grid>
        )
    }

    const renderReferenciaLlegadaTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="referenciaLlegada-textfield"
                    label="Referencia de llegada"
                    variant="outlined"
                    value={referenciaLlegada}
                    onChange={(event) => setReferenciaLlegada(event.target.value)}
                    size='small'
                    disabled={isGeneradoGtSelect!=0}
                    inputProps={{
                        maxlength: 100
                    }}
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleDireccionChange = (nuevaCaracteristica, caracteristicaId) => {
        const nuevasCaracteristicas = caracteristicas.map(caracteristica => {
            if (caracteristica.id === caracteristicaId) {
                return nuevaCaracteristica;
            }
            return caracteristica;
        })
        setCaracteristicas(nuevasCaracteristicas)
    }

    const handleDeleteDireccion = (caracteristicaId) => {
        if(caracteristicas.length === 1) {
            manageAlert('warning', 'Se debe mantener activa al menos un registro de mercadería', true)
        }
        else {
            const nuevasCaracteristicas = caracteristicas.map(caracteristica => {
                if(caracteristica.id === caracteristicaId) {
                    return null;
                }
                return caracteristica
            })

            setCaracteristicas(nuevasCaracteristicas.filter(direccion => 
                direccion !== null
            ))
        }
    }

    const renderCaracteristicas = () => {
        let indice = 0
        return (
            caracteristicas.map((caracteristica) => {
                if((indice < nroMaxCaracteristicas)) {
                    indice = indice + 1
                    return (
                        <MercaderiaCaracteristica
                            key={caracteristica.id}
                            caracteristica={caracteristica}
                            onCaracteristicaChange={(newDireccion) => handleDireccionChange(newDireccion, caracteristica.id)}
                            tiposMercaderia={tiposMercaderia}
                            tiposMoneda={tiposMoneda}
                            indexCaracteristica = {indice}
                            onDelete={() => handleDeleteDireccion(caracteristica.id)}
                            unidadMedida={unidadesMedidaData}
                        />
                    )
                }
                return null
            })
        )
    }

    const handleAddDireccionButton = () => {
        if(caracteristicas.length < nroMaxCaracteristicas) {
            const nuevaCaracteristicaVacia = buildCaracteristicasWithOneEmpty()[0]
            setCaracteristicas(prevData => ([
                ...prevData,
                nuevaCaracteristicaVacia
            ]))
        }
        else {
            manageAlert('warning', `El límite de registros de mercadería es de ${nroMaxCaracteristicas}`, true)
        }
    }

    const renderAddDireccionButton = () => {
        return (
            <Grid container style={{padding: '0.5rem 0rem 0rem 0rem', margin: '0px 0px 0px 0px'}}>
                <Grid item style={{paddingLeft: '0.3rem'}}>
                    <IconButton disabled={isGeneradoGtSelect!=0}  onClick={() => handleAddDireccionButton()} aria-label="add-address" size="large" color="primary">
                        <PlaylistAddIcon fontSize="large" />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    const renderContent = () => {
            return (
                <DialogContent style={{ padding: '0rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                        {renderDividerTitle("Datos Generales")}
                        {renderTipoEnvio()}
                        {renderGuiaRemitenteTextField()}
                        {renderNroBultosTextField()}
                        {renderPesoTotalTextField()}
                        {renderUnidadMedida()}
                        {renderTipoPago()}
                        {tipoPagoSelected !== null && tipoPagoSelected.nombre === "CONTADO" ? renderPagador() : null}
                        {renderObservacionesTextField()}
                        {renderDividerTitle("Datos del Remitente")}
                        {/*renderRemitenteDoc()*/}
                        {renderClienteDoc('remitente', docRemitente, handleRemitenteDocChange, handleRemitenteDocSearch)}
                        {renderClienteRazonSocialTextField('remitente', razonSocialRemitente)}
                        {renderClienteSucursalCheckbox('remitenteIsSucursal', "Sucursal", handleRemitenteCheckBoxChecked, isRemitenteSucursal, "small", false)}
                        {renderClienteDirecciones('remitenteDirecciones', direccionRemitenteSelected, handleRemitenteDireccionSelectedChange, direccionRemitenteInput, handleRemitenteDireccionInputChange, direccionesRemitenteForSelect, "Dirección")}
                        {renderDividerTitle("Datos del Destinatario")}
                        {renderClienteDoc('destinatario', docDestinatario, handleDestinatarioDocChange, handleDestinatarioDocSearch)}
                        {renderClienteRazonSocialTextField('destinatario', razonSocialDestinatario)}
                        {renderClienteSucursalCheckbox('destinatarioIsSucursal', "Sucursal", handleDestinatarioCheckBoxChecked, isDestinatarioSucursal, "small", false)}
                        {renderClienteDirecciones('destinatarioDirecciones', direccionDestinatarioSelected, handleDestinatarioDireccionSelectedChange, direccionDestinatarioInput, handleDestinatarioDireccionInputChange, direccionesDestinatarioForSelect, "Dirección")}
                        {renderReferenciaLlegadaTextField()}
                        {renderCaracteristicas()}
                        {renderAddDireccionButton()}
                        {renderObservacionesInternasTextField()}
                    </Grid>
                </DialogContent>
            )
    }
    
    const handleCloseButton = () => {
        setDialogOpen(false);
    };

    const buildRequestBody = () => {
        const requestBody = {
            idMercaderia: isCreateDialog ? null : mercaderiaToUpdate.idMercaderia,
            remitenteRuc: docRemitente,
            direccionRemitenteId: isRemitenteSucursal ? direccionRemitenteSelected.direccionId : direccionRemitenteSelected.id,
            destinatarioRuc: docDestinatario,
            direccionDestinatarioId: isDestinatarioSucursal ? direccionDestinatarioSelected.direccionId : direccionDestinatarioSelected.id,
            tipoPagoId: tipoPagoSelected.id,
            guiaRemitente: guiaRemitente,
            referenciaLlegada: referenciaLlegada,
            numeroBultos: nroBultos,
            observaciones: observaciones,
            totalKg: pesoTotal,
            pagador: pagadorSelected?pagadorSelected.nombre.charAt(0):null,
            tipoEnvioId: tipoEnvioSelected.id,
            unidadMedida: unidadMedidaSelected.id,

            caracteristicas: caracteristicas.map(caracteristica => {
                return {
                    idCaracteristica: caracteristica.esNueva ? null : caracteristica.id,
                    nombre: caracteristica.nombre,
                    cantidad: caracteristica.cantidad,
                    precioUnitario: caracteristica.precioUnitario,
                    tipoMoneda: caracteristica.tipoMoneda.id,
                    unidadMedida: caracteristica.unidadMedida.id,
                    peso: caracteristica.peso,
                    tipoMercaderia: caracteristica.tipoMercaderia.id,
                    estado: caracteristica.estado
                }
            }),
            direccionRemitenteIsASucursal: isRemitenteSucursal,
            direccionDestinatarioIsASucursal: isDestinatarioSucursal,
            observacionesInternas: observacionesInternas
        }
        
        return requestBody
    }

    const saveMercaderia = async (token, isCreate, reloadData, flagForReloadData, setDialogOpen) => {
        setIsButtonDisabled(true);
        try {
            const response = ((isCreate) ? (await MercaderiaService.create(token, buildRequestBody())) : (await MercaderiaService.update(token, buildRequestBody())))
            if(response) {
                manageAlert('success', ('Mercadería ' + (isCreate ? 'creada' : 'actualizada') + ' exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch(error) {
            manageAlert('error', error.message, true)
        }
        setIsButtonDisabled(false);
    }

    const handleSaveButton = () => {
        const incompleteCaracteristica = caracteristicas.some(caracteristica =>
            caracteristica.cantidad === 0
            || caracteristica.cantidad === ''
            || caracteristica.cantidad === '0'
            || caracteristica.cantidad === '0.0'
            || caracteristica.cantidad === '0.00'
            || caracteristica.nombre === ''
            || caracteristica.tipoMercaderia === null
            || caracteristica.precioUnitario === 0
            || caracteristica.precioUnitario === ''
            || caracteristica.precioUnitario === '0'
            || caracteristica.precioUnitario === '0.0'
            || caracteristica.precioUnitario === '0.00'
            || caracteristica.tipoMoneda === null
            || caracteristica.unidadMedida === null
            | caracteristica.unidadMedida === ''
            || caracteristica.peso === 0
            || caracteristica.peso === ''
            || caracteristica.peso === '0'
            || caracteristica.peso === '0.0'
            || caracteristica.peso === '0.00'
        )
        if(direccionRemitenteSelected !== null && direccionDestinatarioSelected !== null &&
            direccionRemitenteSelected.id === direccionDestinatarioSelected.id
        ) {
            manageAlert('warning', 'Sucursal de origen y destino idénticas', true)
        }
        else {
            if(
                tipoEnvioSelected !== null
                && tipoPagoSelected !== null
                && guiaRemitente !== ''
                && nroBultos !== ''
                && pesoTotal !== ''
                && unidadMedidaSelected !== null
                && docRemitente !== ''
                && razonSocialRemitente !== ''
                && direccionRemitenteSelected !== null
    
                && docDestinatario !== ''
                && razonSocialDestinatario !== ''
                && direccionDestinatarioSelected !== null
    
                && !incompleteCaracteristica
            ) {
                saveMercaderia(token, isCreateDialog, reloadData, flagForReloadData, setDialogOpen)
            }
            else {
                manageAlert('warning', 'Llenar los campos correctamente', true)
            }
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} disabled={isButtonDisabled || (isGeneradoGtSelect!=0 && !isFindStateOne)} variant="contained" >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle(title)}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default MercaderiaCreateUpdate