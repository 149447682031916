import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../img/logo2.png'

// Estilo para el documento PDF
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 3,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    containerStyles: {
        display: 'flex',
        textAlign: 'center', // Centrado del texto dentro de los elementos de texto
    },
    totalStyles: {
        display: 'flex',
        textAlign: 'right', // Centrado del texto dentro de los elementos de texto
    },
    textNameStyles: {
        fontSize: 24
    },
    table: {
        display: "table",
        width: "100%",
        margin: "0 auto", // Centra la tabla
        borderStyle: "solid",
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableColSuperiorFecha: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0,
        backgroundColor: '#047A04',
        height: '100%',
        borderTopLeftRadius: 4,        // Redondeo de la esquina superior izquierda
        borderTopRightRadius: 4       // Redondeo de la esquina superior derecha
    },
    tableColSuperior: {
        width: "48%",
        borderStyle: "solid",
        borderWidth: 0,
        backgroundColor: '#047A04',
        height: '100%',
        borderTopLeftRadius: 4,        // Redondeo de la esquina superior izquierda
        borderTopRightRadius: 4       // Redondeo de la esquina superior derecha
    },
    tableColInferior: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        backgroundColor: '#047A04',
        height: '100%',
        borderTopLeftRadius: 4,        // Redondeo de la esquina superior izquierda
        borderTopRightRadius: 4       // Redondeo de la esquina superior derecha
    },
    tableDataColSuperior: {
        width: "48%",
        borderStyle: "solid",
        borderLeftWidth: 1,           // Grosor del borde izquierdo
        borderRightWidth: 1,          // Grosor del borde derecho
        borderBottomWidth: 1,         // Grosor del borde inferior        borderColor: '#000000',     // Color del borde (negro)
        borderStyle: 'solid',       // Estilo del borde (sólido)
        padding: 3
    },
    tableDataColSuperiorFecha: {
        width: "15%",
        borderStyle: "solid",
        borderLeftWidth: 1,           // Grosor del borde izquierdo
        borderRightWidth: 1,          // Grosor del borde derecho
        borderBottomWidth: 1,         // Grosor del borde inferior        borderColor: '#000000',     // Color del borde (negro)
        borderStyle: 'solid',       // Estilo del borde (sólido)
        padding: 2
    },
    tableDataColInferior: {
        width: "100%",
        borderStyle: "solid",
        borderLeftWidth: 1,           // Grosor del borde izquierdo
        borderRightWidth: 1,          // Grosor del borde derecho
        borderBottomWidth: 1,         // Grosor del borde inferior        borderColor: '#000000',     // Color del borde (negro)
        borderStyle: 'solid',       // Estilo del borde (sólido)
        padding: 3
    },
    tableColSuperiorMedio: {
        width: "4%"
    },
    tableItems: {
        display: "table",
        width: "100%",
        margin: "0 auto", // Centra la tabla
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColCabecera: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        padding: 3
    },
    tableColNombre: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 0
    },
    tableColDatosGenerales: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 0,
    },
    tableColDatosGenerales2: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
    },
    line: {
        borderBottomWidth: 0.3,
        borderBottomColor: '#000000',
        marginBottom: 0.5,
    },
    space: {
        marginBottom: 20, // Ajusta este valor para cambiar el espacio
    },
    subSpace: {
        marginBottom: 5, // Ajusta este valor para cambiar el espacio
    },
    image: {
        width: 200, // Ajusta el ancho según necesites
        height: 60, // Ajusta la altura según necesites
        marginTop: 15, // Margen inferior para separar de otros elementos
        marginLeft: 10
    },
    tableCellCabecera: {
        fontSize: 12,
        textAlign: 'center', // Centrar el texto
        fontWeight: 'heavy',
    },
    cellGuia: {
        backgroundColor: '#047A04',
    },
    textGuia: {
        fontSize: 12,
        color: '#FFFFFF',
        textAlign: 'center', // Centrar el texto
        fontWeight: 'heavy',
    },
    textSuperior: {
        fontSize: 10,
        color: '#FFFFFF',
        textAlign: 'center', // Centrar el texto
        fontWeight: 'heavy',
        margin: 2,
    },
    textSuperiorFecha: {
        fontSize: 8,
        color: '#FFFFFF',
        textAlign: 'center', // Centrar el texto
        fontWeight: 'heavy',
        margin: 2,
    },
    tableColCod: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#047A04'
    },
    tableColCodData: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColDescripcion: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#047A04'
    },
    tableColDescripcionData: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColCantidad: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#047A04'
    },
    tableColCantidadData: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColUnidadMedida: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#047A04'
    },
    tableColUnidadMedidaData: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 5,
        fontSize: 8,
        textAlign: 'center', // Centrar el texto
    },
    qrCode: {
        width: 100,
        height: 100,
    }
});
function convertStringToFloatAndConvertToKgm(pesoStr, unidadMedida) {
    if (pesoStr === null || pesoStr === undefined || pesoStr.trim() === "") {
        return 0; // o puedes devolver otro valor predeterminado
    }
    let pesoConvert = parseFloat(pesoStr);
    if (unidadMedida !=null && unidadMedida.trim()!= "" && unidadMedida=="TNE"){
        pesoConvert = pesoConvert * 1000;
    }
    return pesoConvert;
}
// Componente del PDF
export default function DocumentGuia(props) {

    let pesoTotal = 0;
    return (
        <Document>
            {
                props.data.map((guia) => {
                    pesoTotal=0;
                    return (
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColNombre}>
                                            <Image src={logo} style={styles.image} />
                                        </View>
                                        <View style={styles.tableColCabecera}>
                                            <Text style={styles.tableCellCabecera}>
                                                R.U.C.: 20603423071 {"\n"}
                                            </Text>
                                            <View style={styles.subSpace} />
                                            <View style={styles.cellGuia}>
                                                <Text style={styles.textGuia}>
                                                    GUIA DE REMISION{"\n"}
                                                    DE TRANSPORTISTA
                                                </Text>
                                            </View>
                                            <View style={styles.subSpace} />
                                            <Text style={styles.tableCellCabecera}>
                                                {guia.serie}-{guia.codigo.padStart(8, '0')} {"\n"}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.subSpace} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColSuperiorFecha}>
                                            <Text style={styles.textSuperiorFecha}>
                                                FECHA EMISION
                                            </Text>
                                        </View>
                                        <View style={{ width: "2%" }}></View>
                                        <View style={styles.tableColSuperiorFecha}>
                                            <Text style={styles.textSuperiorFecha}>
                                                FECHA TRASLADO
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableDataColSuperiorFecha}>
                                            <Text style={{ fontSize: 8, margin: 3, textAlign: 'center' }}>
                                                {guia.fechaRegistro}
                                            </Text>
                                        </View>
                                        <View style={{ width: "2%" }}></View>
                                        <View style={styles.tableDataColSuperiorFecha}>
                                            <Text style={{ fontSize: 8, margin: 3, textAlign: 'center' }}>
                                                {guia.fechaRegistro}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.subSpace} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                REMITENTE
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                DESTINATARIO
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 4 }}>
                                                Nro. Doc.: {guia.remitente.ruc} {"\n"}
                                                Razón Social / Nombres: {guia.remitente.razonSocial}
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 3 }}>
                                                Nro. Doc.: {guia.destinatario.ruc} {"\n"}
                                                Razón Social / Nombres: {guia.destinatario.razonSocial}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.subSpace} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                PUNTO PARTIDA
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                PUNTO LLEGADA
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 4 }}>
                                                {guia.remitente.direccion}
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 3 }}>
                                                {guia.destinatario.direccion}
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.space} />
                                <View style={styles.tableItems}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColCod}>
                                            <Text style={styles.textSuperior}>CODIGO</Text>
                                        </View>
                                        <View style={styles.tableColDescripcion}>
                                            <Text style={styles.textSuperior}>DESCRIPCION</Text>
                                        </View>
                                        <View style={styles.tableColUnidadMedida}>
                                            <Text style={styles.textSuperior}>U. MEDIDA</Text>
                                        </View>
                                        <View style={styles.tableColCantidad}>
                                            <Text style={styles.textSuperior}>CANTIDAD</Text>
                                        </View>
                                    </View>
                                    {
                                        guia.caracteristicas.map((item) => {
                                            pesoTotal += convertStringToFloatAndConvertToKgm(item.peso, item.unidadMedida)
                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColCodData}>
                                                        <Text style={styles.tableCell}>M{String(item.id).slice(-4)}</Text>
                                                    </View>
                                                    <View style={styles.tableColDescripcionData}>
                                                        <Text style={styles.tableCell}>{item.nombre}</Text>
                                                    </View>
                                                    <View style={styles.tableColUnidadMedidaData}>
                                                        <Text style={styles.tableCell}>UNIDAD</Text>
                                                    </View>
                                                    <View style={styles.tableColCantidadData}>
                                                        <Text style={styles.tableCell}>{item.cantidad}</Text>
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                                <View style={styles.space} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                DATOS DEL VEHICULO
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableColSuperior}>
                                            <Text style={styles.textSuperior}>
                                                DATOS DEL CONDUCTOR
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 4 }}>
                                                MTC: {guia.transporte.mtc ?  guia.transporte.mtc : "--"}{"\n"}
                                                Nro. Placa: {guia.transporte.nroPlaca} {"\n"}
                                                Nro. Placa Secund.: {guia.transporte.placaSecundaria ?guia.transporte.placaSecundaria  : "--"}
                                            </Text>
                                        </View>
                                        <View style={styles.tableColSuperiorMedio}>
                                        </View>
                                        <View style={styles.tableDataColSuperior}>
                                            <Text style={{ fontSize: 9, margin: 3 }}>
                                                Nro. Doc.: {guia.transporte.nroDocChofer}{"\n"}
                                                Nombres: {guia.transporte.nombreChofer} {"\n"}
                                                Licencia: {guia.transporte.licenciaChofer}{"\n"}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.subSpace} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColInferior}>
                                            <Text style={styles.textSuperior}>
                                                DATOS GENERALES
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableDataColInferior}>
                                            <Text style={{ fontSize: 9, margin: 4 }}>
                                                Guia de Remitente: {guia.guiaRemitente} {"\n"}
                                                Peso total: {pesoTotal == 0? guia.pesoTotal && guia.pesoTotal != 0 ? guia.pesoTotal : '50': pesoTotal}  KGM {"\n"}
                                                Observaciones: {guia.observaciones}{"\n"}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                {
                                    guia.pdfBaseString != '' ?
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColDatosGenerales2}>
                                                    <Image src={guia.pdfBaseString} style={styles.qrCode} />
                                                </View>
                                            </View>
                                        </View> : null
                                }
                            </View>
                        </Page>
                    );
                })
            }
        </Document>
    )
};

